import { Injectable } from '@angular/core';
import { GuiFacade } from '@app/gui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap, tap } from 'rxjs';
import { profileActions } from '../actions/profile.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class ProfileEffects {
    getProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(profileActions.getProfileAction, profileActions.updateProfileSuccessAction),
            mergeMap(() => {
                this.guiFacade.showLoader('get-profile');
                return this.httpService.getProfile().pipe(
                    map((data) => profileActions.getProfileSuccessAction({ payload: data })),
                    catchError(() => of(profileActions.getProfileErrorAction())),
                );
            }),
        );
    });

    getProfileAlways$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(profileActions.getProfileSuccessAction, profileActions.getProfileErrorAction),
                tap(() => this.guiFacade.hideLoader('get-profile')),
            );
        },
        { dispatch: false },
    );

    updateProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(profileActions.updateProfileAction),
            mergeMap((action) => {
                this.guiFacade.showLoader('update-profile');
                return this.httpService.updateProfile(action.value).pipe(
                    map(() => profileActions.updateProfileSuccessAction()),
                    catchError(() => of(profileActions.updateProfileErrorAction())),
                );
            }),
        );
    });

    updateProfileAlways$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(profileActions.updateProfileSuccessAction, profileActions.updateProfileErrorAction),
                tap(() => this.guiFacade.hideLoader('update-profile')),
            );
        },
        { dispatch: false },
    );

    updateProfileSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(profileActions.updateProfileSuccessAction),
                tap(() => {
                    this.guiFacade.hideLoader('update-profile');
                    this.guiFacade.showSuccessMessage('Dane zostały zaktualizowane.');
                }),
            );
        },
        { dispatch: false },
    );

    updatePassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(profileActions.updateProfilePasswordAction),
            mergeMap((action) => {
                this.guiFacade.showLoader('update-password');
                return this.httpService.updatePassword(action.value).pipe(
                    map(() => profileActions.updateProfilePasswordSuccessAction()),
                    catchError((error) => of(profileActions.updateProfilePasswordErrorAction({ error }))),
                );
            }),
        );
    });

    updatePasswordSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(profileActions.updateProfilePasswordSuccessAction),
                tap(() => {
                    this.guiFacade.hideLoader('update-password');
                    this.guiFacade.showSuccessMessage('Hasło zostało zmienione.');
                }),
            );
        },
        { dispatch: false },
    );

    updatePasswordError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(profileActions.updateProfilePasswordErrorAction),
                tap(() => this.guiFacade.hideLoader('update-password')),
            );
        },
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
        private guiFacade: GuiFacade,
    ) {}
}
