import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { profileActions } from '../actions/profile.actions';
import { ErrorsModel, ProfileModel, ProfilePasswordValue, ProfileValueModel } from '../models';
import { selectErrors, selectLoaded, selectLoading, selectProfile, selectSaved, selectSaving } from '../selectors/profile.selector';

@Injectable({
    providedIn: 'root',
})
export class ProfileFacade {
    profile$ = this.store.select(selectProfile).pipe(filter((data): data is ProfileModel => data !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((data): data is boolean => data !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((data): data is boolean => data !== null));
    saved$ = this.store.select(selectSaved).pipe(filter((data): data is boolean => data !== null));
    saving$ = this.store.select(selectSaving).pipe(filter((data): data is boolean => data !== null));
    errors$ = this.store.select(selectErrors).pipe(filter((data): data is ErrorsModel => data !== null));

    constructor(private store: Store) {}

    getProfile(): void {
        this.store.dispatch(profileActions.getProfileAction());
    }

    updateProfile(value: ProfileValueModel): void {
        this.store.dispatch(profileActions.updateProfileAction({ value }));
    }

    updatePassword(value: ProfilePasswordValue): void {
        this.store.dispatch(profileActions.updateProfilePasswordAction({ value }));
    }

    clear(): void {
        this.store.dispatch(profileActions.clearAction());
    }
}
