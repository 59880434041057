import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProfileEffects } from './effects/profile.effects';
import { PROFILE_FEATURE_KEY } from './keys';
import { profileReducer } from './reducers/profile.reducer';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(PROFILE_FEATURE_KEY, profileReducer), EffectsModule.forFeature([ProfileEffects])],
})
export class ProfileStoreModule {}
