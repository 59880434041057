import { createReducer, on } from '@ngrx/store';
import { profileActions } from '../actions/profile.actions';
import { ErrorsModel, ProfileModel } from '../models';

export interface ProfileState {
    profile: ProfileModel | null;
    loading: boolean | null;
    loaded: boolean | null;
    saving: boolean | null;
    saved: boolean | null;
    error: ErrorsModel | null;
}
export const initialState: ProfileState = {
    profile: null,
    loading: null,
    loaded: null,
    saving: null,
    saved: null,
    error: null,
};

export const profileReducer = createReducer(
    initialState,
    on(
        profileActions.getProfileAction,
        (state): ProfileState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        profileActions.getProfileSuccessAction,
        (state, action): ProfileState => ({
            ...state,
            loading: false,
            loaded: true,
            profile: action.payload,
        }),
    ),
    on(
        profileActions.getProfileErrorAction,
        (state): ProfileState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),
    on(
        profileActions.updateProfileAction,
        (state): ProfileState => ({
            ...state,
            saving: true,
        }),
    ),

    on(
        profileActions.updateProfileSuccessAction,
        (state): ProfileState => ({
            ...state,
            saving: false,
            saved: true,
        }),
    ),
    on(
        profileActions.updateProfileErrorAction,
        (state): ProfileState => ({
            ...state,
            saving: false,
            saved: false,
        }),
    ),
    on(
        profileActions.updateProfilePasswordAction,
        (state): ProfileState => ({
            ...state,
            saving: true,
            error: null,
        }),
    ),
    on(
        profileActions.updateProfilePasswordSuccessAction,
        (state): ProfileState => ({
            ...state,
            saving: false,
            saved: true,
            error: null,
        }),
    ),
    on(
        profileActions.updateProfilePasswordErrorAction,
        (state, payload): ProfileState => ({
            ...state,
            saving: false,
            saved: false,
            error: payload.error,
        }),
    ),
    on(
        profileActions.clearAction,
        (state): ProfileState => ({
            ...state,
            ...initialState,
        }),
    ),
);
