import { createAction, props } from '@ngrx/store';
import { ErrorsModel, ProfileModel, ProfilePasswordValue, ProfileValueModel } from '../models';

export enum ProfileActionTypes {
    GET_PROFILE_ACTION = '[Profile] Get profile action',
    GET_PROFILE_SUCCESS_ACTION = '[Profile] Get profile success action',
    GET_PROFILE_ERROR_ACTION = '[Profile] Get profile error action',
    UPDATE_PROFILE_ACTION = '[Profile] Update profile action',
    UPDATE_PROFILE_SUCCESS_ACTION = '[Profile] Update profile success action',
    UPDATE_PROFILE_ERROR_ACTION = '[Profile] Update profile error action',
    UPDATE_PROFILE_PASSWORD_ACTION = '[Profile] Update profile password action',
    UPDATE_PROFILE_PASSWORD_SUCCESS_ACTION = '[Profile] Update profile password success action',
    UPDATE_PROFILE_PASSWORD_ERROR_ACTION = '[Profile] Update profile password error action',
    CLEAR_ACTION = '[Profile] Clear action',
}

const getProfileAction = createAction(ProfileActionTypes.GET_PROFILE_ACTION);
const getProfileSuccessAction = createAction(ProfileActionTypes.GET_PROFILE_SUCCESS_ACTION, props<{ payload: ProfileModel }>());
const getProfileErrorAction = createAction(ProfileActionTypes.GET_PROFILE_ERROR_ACTION);

const updateProfileAction = createAction(ProfileActionTypes.UPDATE_PROFILE_ACTION, props<{ value: ProfileValueModel }>());
const updateProfileSuccessAction = createAction(ProfileActionTypes.UPDATE_PROFILE_SUCCESS_ACTION);
const updateProfileErrorAction = createAction(ProfileActionTypes.UPDATE_PROFILE_ERROR_ACTION);

const updateProfilePasswordAction = createAction(ProfileActionTypes.UPDATE_PROFILE_PASSWORD_ACTION, props<{ value: ProfilePasswordValue }>());
const updateProfilePasswordSuccessAction = createAction(ProfileActionTypes.UPDATE_PROFILE_PASSWORD_SUCCESS_ACTION);
const updateProfilePasswordErrorAction = createAction(ProfileActionTypes.UPDATE_PROFILE_PASSWORD_ERROR_ACTION, props<{ error: ErrorsModel }>());

const clearAction = createAction(ProfileActionTypes.CLEAR_ACTION);

export const profileActions = {
    getProfileAction,
    getProfileSuccessAction,
    getProfileErrorAction,
    updateProfileAction,
    updateProfileSuccessAction,
    updateProfileErrorAction,
    updateProfilePasswordAction,
    updateProfilePasswordSuccessAction,
    updateProfilePasswordErrorAction,
    clearAction,
};
