import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ErrorsModel, ProfileModel, ProfilePasswordValue, ProfileValueModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getProfile(): Observable<ProfileModel> {
        return this.httpClient.get<ApiResponse<ProfileModel>>('user').pipe(map((response) => response.data));
    }

    updateProfile(value: ProfileValueModel): Observable<ProfileModel> {
        return this.httpClient.put<ApiResponse<ProfileModel>>('user', value).pipe(map((response) => response.data));
    }

    updatePassword(value: ProfilePasswordValue): Observable<ProfileModel> {
        return this.httpClient
            .patch<ProfileModel>('user/password', value)
            .pipe(catchError((response: HttpErrorResponse) => throwError(() => new ErrorsModel(response.error?.errors))));
    }
}
