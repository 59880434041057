export class ErrorsModel {
    [key: string]: string[];
    constructor(data: ErrorsDto[]) {
        data.forEach((item) => {
            if (item.propertyPath === 'password' || item.propertyPath === null) {
                if (this['password'] === undefined) {
                    this['password'] = [];
                }
                this['password'].push(item.title);
            }

            if (item.propertyPath === 'passwordOld') {
                if (this['passwordOld'] === undefined) {
                    this['passwordOld'] = [];
                }
                this['passwordOld'].push(item.title);
            }

            if (item.propertyPath === 'passwordConfirm') {
                if (this['passwordConfirm'] === undefined) {
                    this['passwordConfirm'] = [];
                }
                this['passwordConfirm'].push(item.title);
            }
        });
    }
}

export interface ErrorsDto {
    entityId: null;
    propertyPath: string;
    title: string;
}
