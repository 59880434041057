import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PROFILE_FEATURE_KEY } from '../keys';
import { ProfileState } from '../reducers/profile.reducer';

const selectState = createFeatureSelector<ProfileState>(PROFILE_FEATURE_KEY);
export const selectProfile = createSelector(selectState, (state) => state.profile);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectSaving = createSelector(selectState, (state) => state.saving);
export const selectSaved = createSelector(selectState, (state) => state.saved);
export const selectErrors = createSelector(selectState, (state: ProfileState) => state.error);
